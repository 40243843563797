import * as React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageWrapper from '../components/PageWrapper'
import Seo from "../components/Seo"
import { PageTitle } from '../components/PageTitles'
import RecordsFeed from '../components/RecordsFeed'
import Pagination from '../components/Pagination'
import YearFilters from '../components/YearFilters'

const RecordArchive = ({ data, pageContext }) => {

    const [buttonText, setButtonText] = React.useState(" ");

    const recordMetaDesc = data.wp.seo.contentTypes.release.metaDesc;

 

    return (
        <Layout>
             <Seo title={`Records`} description={recordMetaDesc}  />

                <PageWrapper scrollData={data.allWpArtist.nodes}>

                <PageTitle>All Releases</PageTitle>

                <YearFilters dropZone={`left`}  className={`lg:absolute lg:top-3 lg:left-auto lg:right-0`} buttonText={buttonText} setButtonText={setButtonText}  />

                <RecordsFeed recordData={data.allWpRelease.edges} />
    

                <Pagination context={pageContext} />

                

                </PageWrapper>
        </Layout>
    )
}

export default RecordArchive

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWpRelease(
      skip: $skip,
      limit: $limit
      sort: {
        fields: date
        order: DESC
      }
      filter: { status: { eq: "publish" }}
    ) {
    edges {
        node {
          id
          title
          slug
          date
          ltaRecordsSettings {
            recordArtistSelection {
              ... on WpArtist {
                id
                title
                slug
              }
            }
            recordPreorderLink
            recordPurchaseLink
            recordStreamLink
            releaseDate
            lpNumber
          }
          imprints {
              nodes {
                  name
                  id
                  slug
                  count
              }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                      layout: FULL_WIDTH
                      aspectRatio: 1
                  )
                }
              }
            }
          }
        }
      }

      nodes {
          title
          id
          date(formatString: "Y")
          ltaRecordsSettings {
            releaseDate
          }
      }
  }
  
  allWpArtist(
      filter: {bioFeaturedVideos: {isLtaRecordsArtist: {eq: true}}, status: {eq: "publish"}}
      sort: {fields: slug, order: ASC}
      ) {
    nodes {
        slug
        title
        bioFeaturedVideos {
            isLtaRecordsArtist
          }
      }
  }
  wp {
    seo {
      contentTypes {
        release {
          metaDesc
        }
      }
    }
  }

}
`;